import { Dialog } from "@mui/material";
import ExoForm from "../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import { DBRequest } from "../../api/dbRequest";
import { useState } from "react";
import { useParams } from "react-router-dom";
import findDifferences from "../../components/exo/findDifferencesInDataCollection";

const NewScenario = ({ open, onClose, onCreate, startData, onUpdate }) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const fields = [
    {
      label: t("Name"),
      type: "textBig",
      key: "name",
    },
  ];

  function handleSubmit(data) {
    const postReqData = { ...data, project_id: id };

    const isUpdate = Boolean(startData && startData.id);
    const patchReqData = isUpdate ? findDifferences(startData, data) : {};

    DBRequest({
      path: isUpdate ? `scenarios/${startData.id}` : "scenarios",
      data: isUpdate ? patchReqData : postReqData,
      method: isUpdate ? "put" : "post",
      onResponse: isUpdate ? onUpdate : onCreate,
      onLoading: setIsLoading,
    });
  }

  return (
    <Dialog open={open}>
      <ExoForm
        isLoading={isLoading}
        onCancle={onClose}
        header={startData ? t("Edit Scenario") : t("New Scenario")}
        fields={fields}
        startDataCollection={startData}
        onSubmit={handleSubmit}
        className="p-4 flex flex-col gap-4"
      />
    </Dialog>
  );
};

export default NewScenario;
