import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useRequest from "../../api/useRequest";
import GlobalSettings from "./GlobalSettings";
import { useEffect, useState } from "react";
import { LoadingBlur } from "../../components/LoadingBlur";
import { DBRequest } from "../../api/dbRequest";
import MapGridDesigner from "../../components/map-grid-designer/MapGridDesigner";
import { Box, Button, Dialog, useTheme } from "@mui/material";
import * as Yup from "yup";
import ExoAlert from "../../components/exo/ExoAlert";
import { validateDataCollection } from "../../components/exo/checkValidation";
import { validateDataForOptimization } from "../../components/map-grid-designer/helperFunctions/validateDataForOptimization";
import { tokens } from "../../theme/theme";

const Scenario = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { apiData, setApiData, isLoading, setIsLoading } = useRequest({
    path: `scenarios/${id}`,
  });

  // get project

  const [project, setProject] = useState(null);
  useEffect(() => {
    if (apiData) {
      DBRequest({
        path: `projects/${apiData.project_id}`,
        onResponse: setProject,
      });
      if (openSettings === null) checkSettings(apiData.globalSettings);
    }
  }, [apiData]);

  // settings
  const [openSettings, setOpenSettings] = useState(null);
  function checkSettings(settings) {
    validateDataCollection(
      globalSettingsValidation,
      settings,
      onValidation,
      true
    );
  }

  function onValidation(isValid) {
    // open settings if there is something missing
    setOpenSettings(!isValid);
  }
  function handleUpdatedScenario(data) {
    setApiData(data);
    setOpenSettings(false);
  }

  const globalSettingsValidation = Yup.object().shape({
    units: Yup.string().required(t("Is required")),
    auto_divide_large_pipes: Yup.boolean(),
    max_pipe_length: Yup.number().when("auto_divide_large_pipes", {
      is: true,
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.optional(),
    }),
    water_consumption: Yup.number().required(t("Is required")),
    peak_factor: Yup.number().required(t("Is required")),
    wastewater_return_factor: Yup.number().required(t("Is required")),
    infiltration: Yup.number().required(t("Is required")),
    population_type: Yup.string().required("Is required"),
    first_year_population_density: Yup.mixed().when("population_type", {
      is: "populationDensity",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    final_year_population_density: Yup.mixed().when("population_type", {
      is: "populationDensity",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    first_year_population: Yup.mixed().when("population_type", {
      is: "population",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    final_year_population: Yup.mixed().when("population_type", {
      is: "population",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    max_outfall: Yup.number().required(t("Is required")),
    minimum_velocity: Yup.number().required(t("Is required")),
    maximum_velocity: Yup.number().required(t("Is required")),
    minimum_slope: Yup.number().required(t("Is required")),
    maximum_slope: Yup.number().required(t("Is required")),
    use_minimum_shear_stress: Yup.boolean(),
    minimum_shear_stress: Yup.number().when("use_minimum_shear_stress", {
      is: true,
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    manning_roughness: Yup.number().required(t("Is required")),
    maximal_water_depth: Yup.number().required(t("Is required")),
    minimum_cover: Yup.number().required(t("Is required")),
    maximal_buried_depth: Yup.number().required(t("Is required")),
    available_sewer_diameters: Yup.array(),
    use_lift_stations_where_excavation_depth: Yup.boolean().required(
      t("Is required")
    ),
    max_number_of_pump_stations: Yup.number().required(t("Is required")),
    cost_function_id: Yup.number().required(t("Is required")),
  });

  // designer
  const tools = [
    "clear",
    "delete",
    "custom",
    "center",
    "node",
    "edge",
    "population",
  ];
  const [error, setError] = useState(null);
  function handleSaveMapElements(mapElements) {
    DBRequest({
      path: `scenarios/${id}`,
      method: "patch",
      data: { mapElements: mapElements },
      onResponse: handleSavedElements,
      onLoading: setIsLoading,
      onError: handleError,
    });
  }
  function handleSavedElements(data) {
    setApiData({ ...apiData, mapElements: data.mapElements });
  }
  function handleError(errors) {
    setError({ type: "error", message: errors.message });
  }

  const customTools = [
    {
      onClick: () => setOpenSettings(!openSettings),
      label: t("Settings"),
      src: "/assets/svg/tools/settings.svg",
    },
  ];

  // actions
  const [optiData, setOptiData] = useState(null);
  const actions = [
    {
      label: t("Generate Basegrid"),
      color: "warning",
      variant: "outlined",
      onClick: generateBaseGrid,
    },
    {
      label: t("Calculate Dry Weather Inflow"),
      color: "warning",
      variant: "outlined",
      onClick: handleCalcDryWeatherInflow,
    },
    {
      label: t("Download SWMM File"),
      color: "success",
      variant: "outlined",
      onClick: handleDonwloadSWMM,
    },
    {
      label: t("Optimization"),
      color: "success",
      variant: "contained",
      onClick: checkForOptimization,
    },
  ];

  function generateBaseGrid(mapElements, projectAreas) {
    const reqData = {
      projectAreas,
      settings: { ...apiData.globalSettings },
    };

    DBRequest({
      path: `calculate/basegrid/${id}`,
      method: "post",
      data: reqData,
      onResponse: handleNewBaseGrid,
      onLoading: setIsLoading,
      onError: handleError,
    });
  }

  function handleNewBaseGrid(baseGridMapElements) {
    var newMapElements = baseGridMapElements;

    // add to existing
    if (apiData.mapElements)
      newMapElements = [...apiData.mapElements, ...baseGridMapElements];

    setApiData({
      ...apiData,
      mapElements: newMapElements,
    });
  }

  function checkForOptimization(mapElements) {
    const isValid = validateDataForOptimization(apiData.mapElements || []);
    if (isValid) {
      const reqData = {
        mapElements: mapElements,
        settings: { ...apiData.globalSettings },
      };
      DBRequest({
        path: `integrity_check`,
        method: "post",
        data: reqData,
        onResponse: (data) => handleItegrityCheck(data, reqData),
        onLoading: setIsLoading,
        onError: handleError,
      });
    } else {
      setError({
        type: "warning",
        message: t("You need to define at least one Outfall Node"),
      });
    }
  }

  function handleItegrityCheck(data, reqData) {
    if (data.components) {
      setError({
        type: "warning",
        message: t(
          "The network is not fully connected. Please check your connections."
        ),
      });
    } else {
      setOptiData(reqData);
    }
  }

  function startOptimization(data) {
    setOptiData(null);
    DBRequest({
      path: `calculate/optimization/${id}`,
      method: "post",
      data: data,
      onResponse: handleNewMapElements,
      onLoading: setIsLoading,
      onError: handleError,
    });
  }
  function handleNewMapElements(data) {
    setApiData({
      ...apiData,
      mapElements: data,
    });
  }

  function handleCalcDryWeatherInflow(data) {
    const reqData = {
      mapElements: data,
      settings: { ...apiData.globalSettings },
    };
    DBRequest({
      path: `calculate/dwf`,
      method: "post",
      data: reqData,
      onResponse: handleNewMapElements,
      onLoading: setIsLoading,
      onError: handleError,
    });
  }

  function handleDonwloadSWMM(data) {
    DBRequest({
      path: `download/swmm`,
      method: "post",
      data: data,
      onResponse: (res) => window.open(res.url),
    });
  }

  return (
    <Box>
      <LoadingBlur active={!project || isLoading} />
      {apiData && (
        <GlobalSettings
          onUpdate={handleUpdatedScenario}
          scenario={apiData}
          open={openSettings}
          startData={apiData.globalSettings}
          onClose={() => setOpenSettings(false)}
        />
      )}
      <MapGridDesigner
        actions={actions}
        startProjectAreas={project && project.projectAreas}
        startElements={apiData && apiData.mapElements}
        tools={tools}
        onSave={handleSaveMapElements}
        setOpenSettings={setOpenSettings}
        customTools={customTools}
        takeMeBack={{
          label: t("Back"),
          to: `/projects/${project && project.id}`,
        }}
        forceSaveOnActions
      />
      <ExoAlert
        open={error}
        alert={error || {}}
        onClose={() => setError(null)}
      />
      <Dialog
        open={Boolean(optiData)}
        sx={{
          bgcolor: "transparent",
          backdropFilter: "blur(5px)",
          ".MuiPaper-root": {
            bgcolor: "transparent",
            boxShadow: "none",
            width: "100%",
          },
        }}
        onClose={() => setOptiData(null)}
      >
        <Box className="flex items-center justify-evenly gap-4 w-full py-6">
          <OptiCard
            label={t("Rapid Result")}
            src={"/assets/svg/rapid-opti.svg"}
            onClick={() => startOptimization(optiData)}
          />
          <OptiCard
            label={t("Fast Optimization")}
            src={"/assets/svg/fast-opti.svg"}
          />
          <OptiCard
            label={t("Deep Optimization")}
            src={"/assets/svg/deep-opti.svg"}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export const OptiCard = ({ label, onClick, src }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Button
      onClick={onClick}
      variant="contained"
      className="flex flex-col items-center w-[180px] gap-4 hover:scale-105 hover:transition-all"
      sx={{
        borderRadius: "15px",
        bgcolor: colors.contrast[200],
        padding: "18px 10px",
        opacity: onClick ? 1 : 0.5,
        ":hover": {
          bgcolor: colors.contrast[200],
        },
      }}
    >
      <img src={src} className="w-[120px] h-[120px] object-contain" />
      {label}
    </Button>
  );
};

export default Scenario;
