import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ExoAvatar from "../../components/exo/ExoAvatar";
import { PopSettings } from "../../components/PopSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import { DBRequest } from "../../api/dbRequest";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DateTimeLabel from "../../components/label/DateTimeLabel";
import EditIcon from "@mui/icons-material/Edit";

export const ScenarioGridItem = ({
  item,
  onRemoved,
  onAdd,
  onEdit,
  onLoading,
}) => {
  const { t } = useTranslation();

  const user = item.user;

  const actions = [
    {
      label: t("edit"),
      onClick: () => onEdit(item),
      icon: <EditIcon />,
      color: "warning",
    },
    {
      label: t("copy"),
      onClick: handleCopy,
      icon: <ContentCopyIcon />,
      color: "success",
    },
    {
      label: t("delete"),
      onClick: handleDelete,
      icon: <DeleteIcon />,
      color: "error",
    },
  ];
  function handleDelete() {
    DBRequest({
      path: `scenarios/${item.id}`,
      method: "delete",
      onResponse: () => onRemoved(item.id),
      onLoading,
    });
  }

  function handleCopy() {
    DBRequest({
      path: `scenario_copy/${item.id}`,
      method: "post",
      data: { scenario_id: item.id, name: item.name + ` copy` },
      onResponse: (res) => onAdd(res),
      onLoading,
    });
  }

  return (
    <NavLink to={`/scenarios/${item.id}`}>
      <Paper
        className="cursor-pointer w-full p-3 hover:scale-105 relative"
        sx={{ transition: "transform 0.5s ease" }}
      >
        <PopSettings actions={actions} className="absolute right-2 top-2" />
        <img src="/assets/svg/design-view.svg" className="mx-auto w-14 mb-2" />
        <Typography variant="h4" textAlign="center">
          {item.name}
        </Typography>
        <Box className="flex-col justify-between gap-2 pt-2">
          {user.lastName && user.firstName && (
            <Box className="flex items-center gap-2">
              <ExoAvatar
                size="30px"
                picture={user.profile_picture_url}
                name={`${user.lastName} ${user.firstName}`}
              />
              <Typography variant="h5">
                {`${user.lastName} ${user.firstName}`}
              </Typography>
            </Box>
          )}

          <Typography variant="h5" textAlign="right">
            {item.modified_at && <DateTimeLabel dateTime={item.modified_at} />}
          </Typography>
        </Box>
      </Paper>
    </NavLink>
  );
};
