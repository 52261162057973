import { Box, Paper, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme/theme";

export const ListLayout = ({
  ItemComponent,
  items,
  isLoading,
  header = [],
  onRemoved,
  onAdd,
  onEdit,
  onLoading,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  return (
    <table className="w-full">
      <tbody>
        <tr>
          {header.map((element, index) => (
            <Header key={index} header={element} />
          ))}
        </tr>
        {items &&
          items.map((item, index) => (
            <ItemComponent
              key={item.id || index}
              item={item}
              onRemoved={onRemoved}
              onAdd={onAdd}
              onEdit={onEdit}
              onLoading={onLoading}
            />
          ))}
      </tbody>
    </table>
  );
};

export const Header = ({ header }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <th
      className=" text-start border-b-2 pb-2"
      sx={{ borderColor: colors.greenAccent[400] }}
    >
      {header.label}
    </th>
  );
};
