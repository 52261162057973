import { Box, Skeleton } from "@mui/material";
import ExoSkeleton from "../exo/ExoSkeleton";

export const GridLayout = ({
  ItemComponent,
  items,
  isLoading,
  onRemoved,
  onAdd,
  onEdit,
  onLoading,
}) => {
  return (
    <Box className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2">
      {items &&
        items.map((item, index) => (
          <ItemComponent
            key={item.id || index}
            item={item}
            onRemoved={onRemoved}
            onAdd={onAdd}
            onEdit={onEdit}
            onLoading={onLoading}
          />
        ))}
      <ExoSkeleton active={isLoading} n={14} sx={{ height: "170px" }} />
    </Box>
  );
};
