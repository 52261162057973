import { Box, useTheme, Button } from "@mui/material";
import { LayerGroup, MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { MapTools } from "./MapTools";
import { createElement, useEffect, useRef, useState } from "react";
import { tokens } from "../../theme/theme";
import Alert from "../Alert";
import ElementTypes from "./helperFunctions/elementTypes";
import { isNodeInsidePolygon } from "./helperFunctions/isNodeInsidePolygon";
import { MapElementPropertyManager } from "./MapElementPropertyManager";
import { findMapElementById } from "./helperFunctions/findMapElementById";
import { MapUtility } from "./MapUtility";
import { MapActions } from "./MapActions";
import { calculateCenter } from "./helperFunctions/calculateCenter";
import { calculateZoom } from "./helperFunctions/calculateZoom";
import "./customStyles.scss";

const demoTools = [
  "clear",
  "delete",
  "projectArea",
  "center",
  "node",
  "edge",
  "population",
];

const demoActions = [
  {
    label: "default",
    color: "primary",
    variant: "contained",
    onClick: (e) => console.log(e),
  },
  {
    label: "warning",
    color: "warning",
    variant: "contained",
    onClick: (e) => console.log(e),
  },
  {
    label: "warning",
    color: "warning",
    variant: "outlined",
    onClick: (e) => console.log(e),
  },
  {
    label: "error",
    color: "error",
    variant: "contained",
    onClick: (e) => console.log(e),
  },
  {
    label: "error",
    color: "error",
    variant: "outlined",
    onClick: (e) => console.log(e),
  },
  {
    label: "success",
    color: "success",
    variant: "contained",
    onClick: (e) => console.log(e),
  },
  {
    label: "success",
    color: "success",
    variant: "outlined",
    onClick: (e) => console.log(e),
  },
];

const MapGridDesigner = ({
  startCenter,
  startZoom,
  startElements,
  startProjectAreas,
  tools = demoTools,
  actions = demoActions,
  onSave,
  onClose,
  preview,
  noResultMode,
  customTools,
  maxProjectAreas = 1,
  debug,
  takeMeBack,
  forceSaveOnActions,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const mapContainerRef = useRef(null);

  // center of map
  var center =
    startCenter || startProjectAreas
      ? calculateCenter(startProjectAreas)
      : [50, 10];

  // zoom
  var zoom =
    startZoom || startProjectAreas ? calculateZoom(startProjectAreas) : 6;

  useEffect(() => {
    if (!mapContainerRef || !mapContainerRef.current) return;
    var newCenter = center;
    var newZoom = zoom;
    if (!startCenter && startProjectAreas) {
      newCenter = calculateCenter(startProjectAreas);
    }
    if (!startZoom && startProjectAreas) {
      newZoom = calculateZoom(startProjectAreas);
    }
    mapContainerRef.current.setView(newCenter, newZoom);
  }, [startProjectAreas, mapContainerRef]);

  // alert
  const [alert, setAlert] = useState({
    type: "warning",
    message: "",
    open: false,
  });

  const showAlert = (message, type) => {
    setAlert({ alertText: message, alertType: type, alertOpen: true });
  };

  // modes
  const [currentMode, setCurrentMode] = useState("edit"); // current mode of interaction like "view", "edit", "newNode", "newEdge", "newPolygon"

  // elements
  const [mapElements, setMapElements] = useState([]);
  const [projectAreas, setProjectAreas] = useState([]);
  const [selectedElement, setSelectedElement] = useState({});
  const [elementSelection, setElementSelection] = useState([]);

  useEffect(() => {
    if (startElements) {
      setMapElements(startElements);
      setWeightSettings(calcWeightMultiplierForEdges(startElements, 2, 10));
    }
  }, [startElements]);

  useEffect(() => {
    setProjectAreas(startProjectAreas || []);
  }, [startProjectAreas]);

  function handleModeChange() {
    setCurrentMode(currentMode === "view" ? "edit" : "view");
  }

  // select and selection
  function handleMapElementClick(element) {
    if (currentMode === "selection") {
      const existingElement = elementSelection.find(
        (obj) => obj.id === element.id
      );
      if (existingElement) {
        const newElements = elementSelection.filter(
          (obj) => obj.id !== element.id
        );
        setElementSelection(newElements);
      } else {
        setElementSelection([...elementSelection, element]);
      }
    } else {
      setSelectedElement({ ...element });
    }
  }

  function checkIfInSelection(element) {
    const isInSelection = elementSelection.findIndex(
      (obj) => obj.id === element.id
    );
    return isInSelection >= 0;
  }

  function handleProjectAreaCheck(nodePosition) {
    var isValid = false;
    projectAreas.forEach((area) => {
      if (isNodeInsidePolygon(nodePosition, area.positions)) isValid = true;
    });
    return isValid;
  }

  function handleSave() {
    onSave(mapElements, projectAreas);
  }

  // filter
  const [filter, setFilter] = useState(null);

  // calc weight multipier for edges
  const [weightSettings, setWeightSettings] = useState({ a: 20, b: 2 });
  function calcWeightMultiplierForEdges(elements, minWeight, maxWeight) {
    var minDia = null;
    var maxDia = null;
    elements.forEach((element) => {
      if (element.type === "edge" && element.calc) {
        element.calc.forEach((prop) => {
          if (prop.label === "diameter") {
            // update min value
            if (prop.value < minDia || minDia === null) minDia = prop.value;
            // update max value
            if (prop.value > maxDia || maxDia === null) maxDia = prop.value;
          }
        });
      }
    });

    // calc
    const a = (maxWeight - minWeight) / (maxDia - minDia);
    const b = minWeight - a * minDia;

    return { a, b };
  }

  return (
    <Box
      className="flex absolute top-0 left-0 w-full h-full overflow-hidden"
      sx={{
        "& .leaflet-control-attribution.leaflet-control": {
          display: "none",
        },
        "& .leaflet-control-container": {
          display: "none",
        },
        "& .leaflet-tooltip": {
          backgroundColor: "rgba(255,255,255,0.5)",
          border: "none",
          boxShadow: "none",
          padding: "4px 8px",
          backdropFilter: "blur(8px)",
          boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
          "&::before": {
            borderBottomColor: "rgba(255,255,255)",
          },
        },
      }}
    >
      {debug && (
        <Box
          className="absolute flex flex-col gap-2 p-2 top-0 left-0"
          sx={{ zIndex: 999 }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => console.log(mapElements)}
          >
            LOG MapElements
          </Button>
        </Box>
      )}
      <MapElementPropertyManager
        show={!preview || preview.utility}
        selectedElement={
          (selectedElement.id &&
            findMapElementById(mapElements, selectedElement.id)) ||
          {}
        }
        setSelectedElement={setSelectedElement}
        mapElements={mapElements}
        setMapElements={setMapElements}
        disabled={currentMode === "view"}
        setCurrentMode={setCurrentMode}
        elementSelection={elementSelection}
        setElementSelection={setElementSelection}
        currentMode={currentMode}
      />
      <Box
        onClick={handleModeChange}
        className="absolute top-2 right-2 flex justify-center items-center aspect-square rounded-lg w-16 z-50 shadow-lg cursor-pointer"
        sx={{
          bgcolor: colors.contrast[100],
          display:
            (!preview || preview.views) && !noResultMode ? "flex" : "none",
        }}
      >
        {currentMode === "view" ? (
          <img src="/assets/svg/result-view.svg" />
        ) : (
          <img src="/assets/svg/design-view.svg" />
        )}
      </Box>

      <MapContainer
        ref={mapContainerRef}
        doubleClickZoom={false}
        center={center}
        zoom={zoom}
        style={{ height: "100%", width: "100%", zIndex: "0" }}
      >
        <MapUtility
          setFilter={setFilter}
          filter={filter}
          center={center}
          preview={preview}
        />

        <MapActions
          actions={actions}
          onClose={onClose}
          mapElements={mapElements}
          projectAreas={projectAreas}
          onSave={onSave ? handleSave : null}
          takeMeBack={takeMeBack}
          forceSaveOnActions={forceSaveOnActions}
        />

        <MapTools
          show={
            currentMode !== "view" &&
            (!preview || preview.utility) &&
            currentMode !== "selection"
          }
          tools={tools}
          onAlert={showAlert}
          selectedElement={selectedElement}
          mapElements={mapElements}
          setMapElements={setMapElements}
          currentMode={currentMode}
          setCurrentMode={setCurrentMode}
          projectAreas={projectAreas}
          setProjectAreas={setProjectAreas}
          onProjectAreaCheck={handleProjectAreaCheck}
          setSelectedElement={setSelectedElement}
          customTools={customTools}
          maxProjectAreas={maxProjectAreas}
        />

        {mapElements && (
          <>
            {(!preview || preview.projectAreas) && (
              <LayerGroup>
                {projectAreas &&
                  createElement(ElementTypes["projectArea"], {
                    elements: projectAreas,
                    key: "projectAreas",
                    currentMode,
                    projectAreas,
                    setProjectAreas,
                    selectedElement,
                    onSelect: handleMapElementClick,
                    onProjectAreaCheck: handleProjectAreaCheck,
                  })}
              </LayerGroup>
            )}
            {(!preview || preview.area) && (
              <LayerGroup>
                {(filter === null || filter.area) &&
                  mapElements.map((element, index) => {
                    if (element.type !== "area") return;
                    return createElement(ElementTypes[element.type], {
                      element: element,
                      key: element.id,
                      currentMode,
                      mapElements,
                      setMapElements,
                      selectedElement,
                      onSelect: handleMapElementClick,
                      onProjectAreaCheck: handleProjectAreaCheck,
                      index,
                    });
                  })}
              </LayerGroup>
            )}
            {(!preview || preview.edge) && (
              <LayerGroup>
                {(filter === null || filter.edge) &&
                  mapElements.map((element, index) => {
                    if (element.type !== "edge") return;
                    return createElement(ElementTypes[element.type], {
                      element: element,
                      key: element.id,
                      currentMode,
                      mapElements,
                      setMapElements,
                      selectedElement,
                      onSelect: handleMapElementClick,
                      onProjectAreaCheck: handleProjectAreaCheck,
                      weightSettings,
                      index,
                    });
                  })}
              </LayerGroup>
            )}
            {(!preview || preview.node) && (
              <LayerGroup>
                {(filter === null || filter.node) &&
                  mapElements.map((element, index) => {
                    if (element.type !== "node") return;
                    return createElement(ElementTypes[element.type], {
                      element: element,
                      key: element.id,
                      currentMode,
                      mapElements,
                      setMapElements,
                      selectedElement,
                      onSelect: handleMapElementClick,
                      onProjectAreaCheck: handleProjectAreaCheck,
                      index,
                      isInSelection: checkIfInSelection(element),
                    });
                  })}
              </LayerGroup>
            )}
          </>
        )}
      </MapContainer>
      <Alert alert={alert} setAlert={setAlert} />
    </Box>
  );
};

export default MapGridDesigner;
