import { Dialog, Slide } from "@mui/material";
import React, { forwardRef, useState } from "react";
import ExoForm from "../exo/ExoForm";
import { useTranslation } from "react-i18next";
import { DBUploadDataInChunks } from "../../api/dbRequest";
import { LoadingBlur } from "../LoadingBlur";
import ExoAlert from "../exo/ExoAlert";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UploadDemFile = ({
  projectId,
  onClose,
  onUpdate,
  open,
  url = "project/dem_upload/",
}) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(null);

  const fields = [
    {
      label: t("Upload DEM file"),
      subLabel: t("Drag or drop your file here or click to upload"),
      key: "demFile",
      type: "upload",
    },
  ];

  function handleSave(data) {
    setProgress(0);
    DBUploadDataInChunks({
      path: `${url}${projectId}`,
      file: data.demFile,
      chunkSize: 1000000,
      onFinished: handleFinishedUpload,
      onResponse: onUpdate,
      onError: handleError,
      onProgressChange: setProgress,
    });
  }

  function handleFinishedUpload(refresh) {
    setProgress(null);
    onClose();
  }

  const [alert, setAlert] = useState(null);

  function handleError(err) {
    handleFinishedUpload();
    setAlert({
      cancleLabel: t("Close"),
      type: "error",
      message: err.response.data.message,
    });
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",
            maxHeight: "100%",
            padding: "25px",
          },
        }}
      >
        <LoadingBlur active={progress !== null} text={progress} />
        <ExoForm fields={fields} onSubmit={handleSave} onCancle={onClose} />
      </Dialog>
      <ExoAlert
        open={Boolean(alert)}
        alert={alert || {}}
        onClose={() => setAlert(null)}
      />
    </>
  );
};
