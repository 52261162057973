import { Alert, Box, Button, Dialog, Typography } from "@mui/material";
import {
  createElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import FormFields from "../form/FormFields";
import findDifferences from "./findDifferencesInDataCollection";
import { useTranslation } from "react-i18next";
import ExoAlert from "./ExoAlert";
import { LoadingBlur } from "../LoadingBlur";

const ExoForm = forwardRef(
  (
    {
      isLoading,
      fields,
      onSubmit = () => {},
      onCancle,
      onChange = () => {},
      debug = false,
      startDataCollection,
      header = "",
      validationSchema,
      submitText,
      noControls = false,
      sx = {},
      className,
      dialog,
      noDifferences,
      alert = {},
      useStartDataOnReset,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [dataCollection, setDataCollection] = useState({});
    const [alertOpen, setAlertOpen] = useState(false);
    const updateDataCollection = (insertData) => {
      setDataCollection({ ...dataCollection, ...insertData });
    };

    useEffect(() => {
      if (!startDataCollection) return;
      setDataCollection({ ...startDataCollection });
    }, [startDataCollection]);

    function handleSubmit() {
      setAlertOpen(false);
      if (noDifferences) {
        onSubmit(dataCollection);
      } else {
        onSubmit(findDifferences(startDataCollection, dataCollection));
      }
    }

    function requestSubmit() {
      if (alert.message) {
        setAlertOpen(true);
      } else {
        handleSubmit();
      }
    }

    function handleReset() {
      setDataCollection(useStartDataOnReset ? startDataCollection : {});
    }

    // validation --------------------------
    const [valid, setValid] = useState(false);

    const validateDataCollection = async () => {
      if (!validationSchema) {
        setValid(true);
        onChange(dataCollection, true);
        return;
      }
      try {
        await validationSchema.validate(dataCollection);
        // validation passed for the specific field
        setValid(true);
        onChange(dataCollection, true);
      } catch (err) {
        // validation failed for the specific field
        if (debug) console.log("NOT VALID", err);
        onChange(dataCollection, false);
        setValid(false);
      }
    };

    useEffect(() => {
      validateDataCollection();
    }, [dataCollection]);

    // exposed functions
    useImperativeHandle(ref, () => ({
      reset: handleReset,
      valid: valid,
      updateDataCollection: updateDataCollection,
    }));

    const classes = dialog
      ? " flex flex-col gap-4 p-2 relative"
      : "relative flex flex-col gap-4";

    return (
      <Box className={className ? className : classes} sx={sx}>
        <LoadingBlur active={isLoading} text={t("Loading...")} />
        {header && (
          <Typography
            variant="h3"
            textAlign="center"
            className=" backdrop-blur-lg sticky top-0 z-20 py-2"
          >
            {header}
          </Typography>
        )}

        {debug && (
          <Button
            color="warning"
            variant="contained"
            className=" z-40"
            sx={{
              position: "absolute",
            }}
            onClick={() =>
              console.log(
                findDifferences(startDataCollection, dataCollection),
                dataCollection
              )
            }
          >
            LOG
          </Button>
        )}

        {createElement(FormFields, {
          dataCollection,
          startDataCollection: startDataCollection || {},
          updateDataCollection,
          fields,
          validationSchema,
        })}
        {!noControls && (
          <FormControls
            onSubmit={requestSubmit}
            isValid={valid}
            onCancle={onCancle}
            submitText={submitText}
          />
        )}
        <ExoAlert
          open={alertOpen}
          onSubmit={handleSubmit}
          onClose={() => setAlertOpen(false)}
          alert={alert}
        />
      </Box>
    );
  }
);

export const FormControls = ({ onSubmit, onCancle, submitText, isValid }) => {
  const { t } = useTranslation();
  return (
    <Box
      className={
        "flex flex-row w-full items-center " +
        (onCancle ? "justify-between" : "justify-end")
      }
    >
      {onCancle && (
        <Button onClick={onCancle} variant="outlined" color="error">
          {t("Cancle")}
        </Button>
      )}
      <Button
        onClick={onSubmit}
        variant="contained"
        color="success"
        disabled={!isValid}
      >
        {submitText ? submitText : t("Submit")}
      </Button>
    </Box>
  );
};

export default ExoForm;
