import {
  Avatar,
  Box,
  Button,
  Link,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { tokens } from "../theme/theme";
import { tooltip } from "leaflet";
import { t } from "i18next";

const defaultActions = [
  {
    icon: <FileCopyIcon />,
    label: "Copy",
    tooltip: "Copy",
    onClick: () => console.log("Copy"),
  },
  {
    icon: <SaveIcon />,
    label: "Save",
    onClick: () => console.log("Save"),
  },
  {
    icon: <PrintIcon />,
    label: "Print",
    onClick: () => console.log("Print"),
  },
  {
    icon: <ShareIcon />,
    label: "Share",
    onClick: () => console.log("Share"),
  },
];

export const PopSettings = ({
  vertical = "bottom",
  horizontal = "left",
  actions = defaultActions,
  className,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={className}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-0"
        anchorOrigin={{
          vertical,
          horizontal,
        }}
      >
        <Box className="flex flex-col gap-2 items-center p-4">
          <Typography variant="h6" textAlign="left" className="w-full">
            {t("actions")}
          </Typography>
          {actions.map((action, index) => (
            <MenuItem key={index} action={action} />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

const MenuItem = ({ action }) => {
  function handleClick(event) {
    event.preventDefault();
    action.onClick();
  }
  return (
    <Tooltip title={action.tooltip} placement="left">
      <Button
        onClick={handleClick}
        variant="outlined"
        color={action.color || "success"}
        className=" w-full"
        sx={{
          textTransform: "none",
        }}
      >
        <Box className="flex gap-2 justify-normal items-center w-full">
          {action.icon}
          <Typography>{action.label}</Typography>
        </Box>
      </Button>
    </Tooltip>
  );
};
