import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CardContainer from "../../../components/cards/CardContainer";
import { systemImgMap } from "../../../components/project/projectSystemMap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { calculatePolygonArea } from "../../../components/map-grid-designer/helperFunctions/calcPolygonArea";
import UploadIcon from "@mui/icons-material/Upload";
import { tokens } from "../../../theme/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import { DBRequest } from "../../../api/dbRequest";
import { Opacity } from "@mui/icons-material";

export const TitleCard = ({ project, onOpenDemUpload, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  const [area, setArea] = useState(0);
  useEffect(() => {
    if (project.projectAreas) {
      var newArea = 0;
      project.projectAreas.forEach((element) => {
        newArea += calculatePolygonArea(element.positions);
      });
      setArea(newArea);
    }
  }, [project]);

  function handleRemoveDemFile() {
    DBRequest({
      path: `project/remove_dem/${project.id}`,
      method: "delete",
      onResponse: () => onUpdate({ ...project, dem_file_name: null }),
    });
  }

  const hasActiveDemFile = Boolean(project.dem_file_name);
  return (
    <Box className="flex flex-col gap-4">
      <CardContainer className="h-full">
        <img
          className="w-24 h-24 object-contain mx-auto"
          src={systemImgMap[project.system]}
        />
        <Typography variant="h3">{project.name}</Typography>
        <Typography variant="h4">
          {parseFloat(area / 1000000).toFixed(3)} km²
        </Typography>
      </CardContainer>
      <CardContainer className="flex justify-center items-center py-6 gap-4 group relative">
        {hasActiveDemFile && (
          <IconButton
            onClick={handleRemoveDemFile}
            className=" opacity-0 group-hover:opacity-100 top-1 right-1"
            sx={{
              transition: "opacity 0.2s ease",
              position: "absolute",
            }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        )}
        <Box className="w-full block">
          <Typography
            textAlign="center"
            color={
              hasActiveDemFile ? colors.greenAccent[400] : colors.primary[100]
            }
            fontWeight={hasActiveDemFile ? 600 : 400}
          >
            {t(hasActiveDemFile ? "active" : "no active DEM file")}
          </Typography>
          {hasActiveDemFile && (
            <Typography className="w-full break-words" variant="h6">
              {project.dem_file_name}
            </Typography>
          )}
        </Box>

        <Button
          variant={hasActiveDemFile ? "outlined" : "contained"}
          color="secondary"
          startIcon={<UploadIcon />}
          onClick={onOpenDemUpload}
        >
          {t(hasActiveDemFile ? "Change dem file" : "Upload dem file")}
        </Button>
      </CardContainer>
    </Box>
  );
};
