import React from "react";
import { FileUpload } from "../../upload/FileUpload";

export const TypeUpload = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  function handleChange(e) {
    updateValidationOnChange(field.key, e[0]);
  }
  return (
    <FileUpload
      onChange={handleChange}
      label={field.label}
      subLabel={field.subLabel}
    />
  );
};
