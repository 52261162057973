import { Box, useTheme, Typography } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Header from "./position-modules/Header";
import Entry from "./position-modules/Entry";
import Controls from "./position-modules/Controls";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme/theme";
import Trans from "../Trans";
import { v4 as uuid } from "uuid";

const ExoPositions = forwardRef(
  (
    {
      onChange,
      startData = [],
      onFocus = () => {},
      descriptionVars = [],
      showVars,
      hideNewPosition,
      emptyStart,
      columns = [
        {
          text: Trans("Diameter"),
          width: "auto",
          align: "right",
          key: "diameter",
          editable: true,
          hidden: false,
          default: 0,
          number: true,
          decimalPlaces: 2,
          unit: "m",
        },
        {
          text: Trans("Minimum Velocity"),
          width: "auto",
          align: "right",
          key: "diameter",
          editable: true,
          hidden: false,
          default: 0,
          number: true,
          decimalPlaces: 2,
          unit: "%",
        },
        {
          text: Trans("Minimum Slope"),
          width: "auto",
          align: "right",
          key: "diameter",
          editable: true,
          hidden: false,
          default: 0,
          number: true,
          decimalPlaces: 2,
          unit: "%",
        },
      ],
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const { t } = useTranslation();

    const [items, setItems] = useState(startData || []);
    const [defaultPosition, setDefaultPosition] = useState(null);

    useEffect(() => {
      var newDefaultPositions = {};
      columns.forEach((column) => {
        newDefaultPositions[column.key] = column.default;
      });
      setDefaultPosition(newDefaultPositions);
    }, []);

    useEffect(() => {
      var newSum = 0;
      var newTax = 0;
      items.forEach((element) => {
        newSum += element.amount * element.price;
        newTax += element.amount * element.price * (element.taxRate / 100);
      });

      onChange(items);
      if (emptyStart) return;
      if (items.length === 0 && defaultPosition) {
        handleNewPosition(defaultPosition);
      }
    }, [items, defaultPosition]);

    // drag stuff
    const [isDragging, setIsDragging] = useState(false);

    const handleOnDragStart = (result) => {
      setIsDragging(true);
    };

    const handleOnDragEnd = (result) => {
      setIsDragging(false);
      const { source, destination } = result;
      // If item was dropped outside of a droppable, do nothing
      if (!destination) {
        return;
      }
      // If item was dropped in the "trash" droppable, delete the item
      var newItems = [...items];
      if (destination.droppableId === "trash") {
        newItems.splice(source.index, 1);
      } else {
        // Otherwise, reorder the items as usual
        const [removed] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, removed);
      }
      setItems(newItems);
    };

    function handleNewPosition(position) {
      setItems([...items, { ...position, id: uuid() }]);
    }

    // exposed functions
    useImperativeHandle(ref, () => ({
      setItems: setItems,
      items: items,
    }));

    return (
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onDragStart={handleOnDragStart}
      >
        <Box
          className="relative"
          sx={{
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
            "& .MuiAccordionSummary-root": {
              minHeight: "26px",
            },
            "& th:first-child": {
              borderTopLeftRadius: "8px",
            },
            "& th:last-child": {
              borderTopRightRadius: "8px",
            },
            "& tr:last-child>td:first-child": {
              borderBottomLeftRadius: "8px",
            },
            "& tr:last-child>td:last-child": {
              borderBottomRightRadius: "8px",
            },
            "& td, th": {
              backgroundColor: colors.contrast[300],
            },
          }}
        >
          <Droppable droppableId="positions">
            {(provided) => (
              <table
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full"
              >
                <tbody>
                  <tr>
                    {columns.map((col, index) => (
                      <Header key={"header-" + col.key} col={col}>
                        {col.text}
                      </Header>
                    ))}
                  </tr>

                  {items.length === 0 && (
                    <tr>
                      <td colSpan={9999} className=" h-10">
                        <Typography variant="body1" textAlign="center">
                          {t("No Position")}
                        </Typography>
                      </td>
                    </tr>
                  )}

                  {items.map((item, index) => (
                    <Draggable
                      key={"drag-" + item.id + "-" + index}
                      draggableId={"item-" + item.id + "-" + index}
                      index={index}
                    >
                      {(provided) => (
                        <tr
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {columns.map((col, i) => (
                            <Entry
                              key={index + " " + i}
                              col={col}
                              setItems={setItems}
                              items={items}
                              index={index}
                              showVars={showVars}
                              onFocus={(ref) =>
                                onFocus({
                                  index: index,
                                  key: col.key,
                                  ref: ref,
                                })
                              }
                              descriptionVars={descriptionVars}
                            >
                              {item[col.key]}
                            </Entry>
                          ))}
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <Controls
                    hideNewPosition={Boolean(hideNewPosition)}
                    cols={columns}
                    onNewPosition={() => handleNewPosition(defaultPosition)}
                  />
                </tbody>
              </table>
            )}
          </Droppable>
          <Droppable droppableId="trash">
            {(provided, snapshot) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex"
                sx={{
                  width: "60px",
                  height: "60px",
                }}
              >
                <DeleteOutlineIcon
                  className="absolute bottom-0 left-0 "
                  sx={{
                    fontSize: "60px",
                    opacity: isDragging ? "1" : "0",
                    transform: isDragging ? "scale(1)" : "scale(0)",
                    color: snapshot.isDraggingOver
                      ? colors.redAccent[500]
                      : colors.primary[500],
                    transition: "0.4s",
                  }}
                />
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
      </DragDropContext>
    );
  }
);

export default ExoPositions;
