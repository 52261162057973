import ExoPositions from "../../exo/ExoPositions";
import { useTranslation } from "react-i18next";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const TypeDiameterTable = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const { t } = useTranslation();

  function handleTextChange(value) {
    updateValidationOnChange(field.key, value);
  }
  const columns = [
    {
      text: "",
      width: "20px",
      align: "left",
      key: "drag",
      className: "justify-start",
      hidden: false,
      custom: (element, unit) => <DragHandleIcon />,
    },
    {
      text: t("Diameter"),
      width: "auto",
      align: "right",
      key: "diameter",
      editable: true,
      hidden: false,
      default: 0.15,
      number: true,
      decimalPlaces: 2,
      unit: "m",
    },
    {
      text: t("Minimum Velocity"),
      width: "auto",
      align: "right",
      key: "minimum_velocity",
      editable: true,
      hidden: false,
      default: 0.6,
      number: true,
      decimalPlaces: 2,
      unit: "m/s",
    },
    {
      text: t("Minimum Slope"),
      width: "auto",
      align: "right",
      key: "minimum_slope",
      editable: true,
      hidden: false,
      default: 0.003,
      number: true,
      decimalPlaces: 3,
    },
    {
      text: t("Maximum water depth"),
      width: "auto",
      align: "right",
      key: "maximal_water_depth",
      editable: true,
      hidden: false,
      default: 0.75,
      number: true,
      decimalPlaces: 2,
      unit: "h/D",
    },
  ];
  return (
    <ExoPositions
      columns={columns}
      onChange={handleTextChange}
      startData={dataCollection[field.key]}
    />
  );
};

export default TypeDiameterTable;
